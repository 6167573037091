/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductInfo
 */
export interface ProductInfo {
    /**
     * 
     * @type {string}
     * @memberof ProductInfo
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductInfo
     */
    treeSpeciesId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductInfo
     */
    cells: number;
    /**
     * 
     * @type {number}
     * @memberof ProductInfo
     */
    boxes: number;
    /**
     * 
     * @type {number}
     * @memberof ProductInfo
     */
    bags: number;
    /**
     * 
     * @type {number}
     * @memberof ProductInfo
     */
    platforms: number;
}

export function ProductInfoFromJSON(json: any): ProductInfo {
    return ProductInfoFromJSONTyped(json, false);
}

export function ProductInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'treeSpeciesId': json['treeSpeciesId'],
        'cells': json['cells'],
        'boxes': json['boxes'],
        'bags': json['bags'],
        'platforms': json['platforms'],
    };
}

export function ProductInfoToJSON(value?: ProductInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'treeSpeciesId': value.treeSpeciesId,
        'cells': value.cells,
        'boxes': value.boxes,
        'bags': value.bags,
        'platforms': value.platforms,
    };
}


