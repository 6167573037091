import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { NavLink } from 'react-router-dom';
import { Facility, ProductInfo, TreeSpecies } from "../../generated/client";
import strings from "../../localization/strings";

import {
  Button,
  Grid,
  Loader,
  Table,
  Visibility,
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";

export interface Props {
  keycloak?: Keycloak;
  productInfos?: ProductInfo[];
  facility: Facility;
  onProductInfosFound?: (productInfos: ProductInfo[]) => void,
  onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => void,
  onError: (error: ErrorMessage | undefined) => void
}

export interface State {
  productInfos: ProductInfo[];
  treeSpecies: TreeSpecies[];
  showInActive: boolean;
}

class ProductInfoList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productInfos: [],
      treeSpecies: [],
      showInActive: false
    };
  }

  /**
   * Component did mount life-sycle event
   */
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate = async (prevprops: Props, prevstate: State) => {
    if (prevstate.showInActive !== this.state.showInActive) {
      this.loadData();
    }
  }

  /**
   * Render productInfos list view
   */
  public render() {
    if (!this.props.productInfos) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    const tableRows = this.props.productInfos.map((productInfo, i) => {
      const productPath = `/productInfos/${productInfo.id}`;
      const treeSpecies = this.state.treeSpecies.find((treeSpecies) => treeSpecies.id === productInfo.treeSpeciesId);
      const species = LocalizedUtils.getLocalizedValue(treeSpecies?.name)
      return (
        <Table.Row key={productInfo.id}>
          <Table.Cell>{ species }</Table.Cell>
          <Table.Cell>{ productInfo.bags }</Table.Cell>
          <Table.Cell>{ productInfo.boxes }</Table.Cell>
          <Table.Cell>{ productInfo.cells }</Table.Cell>
          <Table.Cell>{ productInfo.platforms }</Table.Cell>
          <Table.Cell textAlign='right'>
            <NavLink to={productPath}>
                <Button className="submit-button">{strings.open}</Button>
            </NavLink>
          </Table.Cell>
        </Table.Row>
      )
    });

    return (
      <Grid>
      <Grid.Row className="content-page-header-row" style={{flex: 1,justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}}>
        <h2>{strings.productInfos}</h2>
        <NavLink to="/createProductInfo">
          <Button className="submit-button">{strings.newProductInfo}</Button>
        </NavLink>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
        <Visibility>
        <div className="scrollable-table">
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{ strings.species }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.bags }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.boxes }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.cells }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.treeSpeciesPlatforms }</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { tableRows }
            </Table.Body>
          </Table>
          </div>
        </Visibility>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    );
  }

  private loadData = async () => {
    const { keycloak, facility, onTreeSpeciesFound, onProductInfosFound } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      const productInfoService = await Api.getProductInfoService(keycloak);
      const productInfos = await productInfoService.listProductInfo({
        facility: facility
      });
      onProductInfosFound && onProductInfosFound(productInfos);
      this.setState({ productInfos: productInfos });

      const treeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      const treeSpecies = await treeSpeciesService.listTreeSpecies({
        facility: facility
      })
      onTreeSpeciesFound && onTreeSpeciesFound(treeSpecies);
      this.setState({ treeSpecies: treeSpecies });
    } catch (e: any) {
      this.props.onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    productInfos: state.productInfos,
    treeSpecies: state.treeSpecies,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onProductInfosFound: (productInfos: ProductInfo[]) => dispatch(actions.productInfosFound(productInfos)),
    onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoList);