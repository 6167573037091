/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StorageDiscard
 */
export interface StorageDiscard {
    /**
     * 
     * @type {string}
     * @memberof StorageDiscard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageDiscard
     */
    productId: string;
    /**
     * 
     * @type {Date}
     * @memberof StorageDiscard
     */
    discardDate: Date;
    /**
     * 
     * @type {string}
     * @memberof StorageDiscard
     */
    packageSizeId: string;
    /**
     * 
     * @type {number}
     * @memberof StorageDiscard
     */
    discardAmount: number;
}

export function StorageDiscardFromJSON(json: any): StorageDiscard {
    return StorageDiscardFromJSONTyped(json, false);
}

export function StorageDiscardFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageDiscard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': json['productId'],
        'discardDate': (new Date(json['discardDate'])),
        'packageSizeId': json['packageSizeId'],
        'discardAmount': json['discardAmount'],
    };
}

export function StorageDiscardToJSON(value?: StorageDiscard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'discardDate': (value.discardDate.toISOString()),
        'packageSizeId': value.packageSizeId,
        'discardAmount': value.discardAmount,
    };
}


