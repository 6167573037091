import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import Api from "../../api";
import { NavLink } from 'react-router-dom';
import { Facility, TreeSpecies, SeedBatch } from "../../generated/client";
import strings from "../../localization/strings";
import { StoreState, ErrorMessage } from "../../types/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  Button,
  Grid,
  Loader,
  Table,
  Visibility
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";

export interface Props {
  keycloak?: Keycloak;
  seedBatches?: SeedBatch[];
  facility: Facility;
  onSeedBatchesFound?: (seedBatches: SeedBatch[]) => void,
  onError: (error: ErrorMessage | undefined) => void
}

export interface State {
  seedBatches: SeedBatch[],
  treeSpecies: TreeSpecies[],
  showPassive: boolean,
  loading: boolean
}

class SeedBatchList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      treeSpecies: [],
      seedBatches: [],
      showPassive: false,
      loading: false
    };
  }

  /**
   * Component did mount life-sycle event
   */
  public async componentDidMount() {
    try {
      if (!this.props.keycloak) {
        return;
      }
      const treeSpeciesService = await Api.getTreeSpeciesService(this.props.keycloak);
      const treeSpecies = await treeSpeciesService.listTreeSpecies({ facility: this.props.facility });
      const seedBatchService = await Api.getSeedBatchesService(this.props.keycloak);
      const seedBatches = await seedBatchService.listSeedBatches({
        facility: this.props.facility
      });
      seedBatches.sort((a, b) => {
        let nameA = a.amount || "";
        let nameB = b.amount || "";
        if(nameA < nameB) { return -1; }
        if(nameA > nameB) { return 1; }
        return 0;
      });
      this.setState({ treeSpecies });
      this.props.onSeedBatchesFound && this.props.onSeedBatchesFound(seedBatches);
    } catch (e: any) {
      this.props.onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  private renderSeedBatchRow = (seedBatch: SeedBatch) => {
    const treeSpecies = this.state.treeSpecies.find((treeSpecies) => treeSpecies.id === seedBatch.treeSpeciesId);
    const species = LocalizedUtils.getLocalizedValue(treeSpecies?.name)
    return (
      <Table.Row key={seedBatch.id}>
        <Table.Cell>{ species }</Table.Cell>
        <Table.Cell>{ seedBatch.amount }</Table.Cell>
        <Table.Cell>{ seedBatch.svCode }</Table.Cell>
        <Table.Cell>{ seedBatch.thousandSeedWeight }</Table.Cell>
        <Table.Cell>{ seedBatch.reportedGermination }</Table.Cell>
        <Table.Cell>{ seedBatch.testedGermination }</Table.Cell>
        <Table.Cell>{ seedBatch.baseCertificateNumber }</Table.Cell>
        <Table.Cell>{ seedBatch.price }</Table.Cell>
        <Table.Cell>{ seedBatch.purity }</Table.Cell>
        <Table.Cell>{ seedBatch.containerNumber }</Table.Cell>
        <Table.Cell>{ seedBatch.seedIdentifier }</Table.Cell>
        <Table.Cell>{ seedBatch.baseMaterialType }</Table.Cell>
        <Table.Cell>{ seedBatch.sourceArea }</Table.Cell>
        <Table.Cell>{ seedBatch.forestryClass }</Table.Cell>

        <Table.Cell textAlign='right'>
          <NavLink to={`/seedBatches/${seedBatch.id}`}>
              <Button className="submit-button">{strings.open}</Button>
          </NavLink>
        </Table.Cell>
      </Table.Row>
    )
  }

  /**
   * Render seed  batchlist view
   */
  public render() {
    const { seedBatches } = this.props; 
    if (!seedBatches) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    const tableRows = seedBatches!.map((seedBatch, i) => this.renderSeedBatchRow(seedBatch));

  return (
    <Grid>
      <Grid.Row className="content-page-header-row" style={{flex: 1,justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}}>
        <h2>{strings.seedBatches}</h2>
        <NavLink to="/createSeedBatch">
          <Button className="submit-button">{strings.newSeedBatch}</Button>
        </NavLink>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
        <Visibility>
        <div className="scrollable-table">
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{ strings.species }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.amount }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.svCode }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.thousandSeedWeight }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.reportedGermination }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.testedGermination }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.baseCertificateNumber }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.price }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.purity }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.containerNumber }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.seedIdentifier }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.baseMaterialType }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.sourceArea }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.forestryClass }</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { tableRows }
            </Table.Body>
          </Table>
          </div>
        </Visibility>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    seedBatches: state.seedBatches,
    seedBatch: state.seedBatch,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onSeedBatchesFound: (seedBatches: SeedBatch[]) => dispatch(actions.seedBatchesFound(seedBatches)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeedBatchList);