/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CultivationAction,
    CultivationActionFromJSON,
    CultivationActionToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
} from '../models';

export interface CreateCultivationActionRequest {
    cultivationAction: CultivationAction;
    facility: Facility;
}

export interface DeleteCultivationActionRequest {
    facility: Facility;
    cultivationActionId: string;
}

export interface FindCultivationActionRequest {
    facility: Facility;
    cultivationActionId: string;
}

export interface ListCultivationActionsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdateCultivationActionRequest {
    cultivationAction: CultivationAction;
    facility: Facility;
    cultivationActionId: string;
}

/**
 * 
 */
export class CultivationActionsApi extends runtime.BaseAPI {

    /**
     * Create new cultivation action
     */
    async createCultivationActionRaw(requestParameters: CreateCultivationActionRequest): Promise<runtime.ApiResponse<CultivationAction>> {
        if (requestParameters.cultivationAction === null || requestParameters.cultivationAction === undefined) {
            throw new runtime.RequiredError('cultivationAction','Required parameter requestParameters.cultivationAction was null or undefined when calling createCultivationAction.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/cultivationActions`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CultivationActionToJSON(requestParameters.cultivationAction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CultivationActionFromJSON(jsonValue));
    }

    /**
     * Create new cultivation action
     */
    async createCultivationAction(requestParameters: CreateCultivationActionRequest): Promise<CultivationAction> {
        const response = await this.createCultivationActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a cultivation action
     */
    async deleteCultivationActionRaw(requestParameters: DeleteCultivationActionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteCultivationAction.');
        }

        if (requestParameters.cultivationActionId === null || requestParameters.cultivationActionId === undefined) {
            throw new runtime.RequiredError('cultivationActionId','Required parameter requestParameters.cultivationActionId was null or undefined when calling deleteCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/cultivationActions/{cultivationActionId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"cultivationActionId"}}`, encodeURIComponent(String(requestParameters.cultivationActionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a cultivation action
     */
    async deleteCultivationAction(requestParameters: DeleteCultivationActionRequest): Promise<void> {
        await this.deleteCultivationActionRaw(requestParameters);
    }

    /**
     * Find a cultivation action
     */
    async findCultivationActionRaw(requestParameters: FindCultivationActionRequest): Promise<runtime.ApiResponse<CultivationAction>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findCultivationAction.');
        }

        if (requestParameters.cultivationActionId === null || requestParameters.cultivationActionId === undefined) {
            throw new runtime.RequiredError('cultivationActionId','Required parameter requestParameters.cultivationActionId was null or undefined when calling findCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/cultivationActions/{cultivationActionId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"cultivationActionId"}}`, encodeURIComponent(String(requestParameters.cultivationActionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CultivationActionFromJSON(jsonValue));
    }

    /**
     * Find a cultivation action
     */
    async findCultivationAction(requestParameters: FindCultivationActionRequest): Promise<CultivationAction> {
        const response = await this.findCultivationActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all cultivation actions
     */
    async listCultivationActionsRaw(requestParameters: ListCultivationActionsRequest): Promise<runtime.ApiResponse<Array<CultivationAction>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listCultivationActions.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/cultivationActions`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CultivationActionFromJSON));
    }

    /**
     * List all cultivation actions
     */
    async listCultivationActions(requestParameters: ListCultivationActionsRequest): Promise<Array<CultivationAction>> {
        const response = await this.listCultivationActionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a cultivation action
     */
    async updateCultivationActionRaw(requestParameters: UpdateCultivationActionRequest): Promise<runtime.ApiResponse<CultivationAction>> {
        if (requestParameters.cultivationAction === null || requestParameters.cultivationAction === undefined) {
            throw new runtime.RequiredError('cultivationAction','Required parameter requestParameters.cultivationAction was null or undefined when calling updateCultivationAction.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateCultivationAction.');
        }

        if (requestParameters.cultivationActionId === null || requestParameters.cultivationActionId === undefined) {
            throw new runtime.RequiredError('cultivationActionId','Required parameter requestParameters.cultivationActionId was null or undefined when calling updateCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/cultivationActions/{cultivationActionId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"cultivationActionId"}}`, encodeURIComponent(String(requestParameters.cultivationActionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CultivationActionToJSON(requestParameters.cultivationAction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CultivationActionFromJSON(jsonValue));
    }

    /**
     * Updates a cultivation action
     */
    async updateCultivationAction(requestParameters: UpdateCultivationActionRequest): Promise<CultivationAction> {
        const response = await this.updateCultivationActionRaw(requestParameters);
        return await response.value();
    }

}
