import * as React from "react";
import Keycloak from 'keycloak-js';
import Api from "../../api";
import {
  Event,
  SowingEventData,
  WastageEventData,
  CultivationAction,
  SeedBatch,
  WastageReason,
  EventType,
  Facility,
  Location,
  TransferEventData,
  Batch,
  PackingEventData,
  PerformedActionEventData,
  LoadEventData,
  PeatBatch,
  GritBatch,
  BatchStatus,
  PackingContainerType,
  PackingType} from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";
import { DateTimeInput } from 'semantic-ui-calendar-react';
import * as actions from "../../actions";
import { StoreState } from "../../types/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DateTime } from "luxon";

import {
  Grid,
  Button,
  Loader,
  Form,
  Message,
  DropdownProps,
  Confirm,
  TextAreaProps,
  DropdownItemProps,
  InputOnChangeData,
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";
import moment from "moment";
import { ErrorMessage } from "../../types";
import { FormContainer } from "../FormContainer";
import { Select } from "semantic-ui-react";

/**
 * Interface representing component properties
 */
interface Props {
  keycloak?: Keycloak;
  facility: Facility;
  onError: (error: ErrorMessage | undefined) => void;
}

/**
 * Interface representing component state
 */
interface State {
  open: boolean;
  loading: boolean;
  saving: boolean;
  messageVisible: boolean;
  redirect: boolean;
  event: Event;
  performedCultivationActions?: CultivationAction[];
  seedBatches?: SeedBatch[];
  locations: Location[];
  wastageReasons?: WastageReason[];
  batches: Batch[];
  peatBatches: PeatBatch[];
  gritBatches: GritBatch[];
}

/**
 * React component for edit event view
 */
class CreateEvent extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      locations: [],
      loading: false,
      redirect: false,
      saving: false,
      messageVisible: false,
      open: false,
      event: {
        startTime: new Date(),
        data: {},
        type: EventType.Sowing,
        batchId: ""
      },
      performedCultivationActions: [],
      batches: [],
      gritBatches: [],
      peatBatches: []
    };
  }

  /**
   * Component did mount life-sycle method
   */
  public componentDidMount = async () => {
    const  { keycloak, facility } = this.props;
    if (!keycloak) {
      return;
    }

    this.setState({loading: true});

    const [locationsService, performedCultivationActionsService, batchesService, gritBatchService, peatBatchService] = await Promise.all([
      Api.getLocationsService(keycloak),
      Api.getPerformedCultivationActionsService(keycloak),
      Api.getBatchesService(keycloak),
      Api.getGritBatchesService(keycloak),
      Api.getPeatBatchesService(keycloak)
    ]);
    const [locations, performedCultivationActions, batches, gritBatches, peatBatches] = await Promise.all([
      locationsService.listLocations({ facility: facility }),
      performedCultivationActionsService.listCultivationActions({ facility: facility }),
      batchesService.listBatches({ facility: facility }),
      gritBatchService.listGritBatches({ facility: facility }),
      peatBatchService.listPeatBatches({ facility: facility })
    ]);

    this.setState({
      loading: false,
      locations: locations,
      batches: batches,
      gritBatches: gritBatches,
      peatBatches: peatBatches,
      performedCultivationActions: performedCultivationActions ?? [],
      event: {
        startTime: DateTime.now().toJSDate(),
        data: {},
        type: EventType.Sowing,
        batchId: ""
      }
    });
  }

  public componentDidUpdate = () => {
    const data = (this.state.event ? this.state.event.data : {}) as any;
    if (!data) {
      return;
    }
  }

  /**
   * Render edit product view
   */
  public render() {
    if (this.state.loading) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    if (this.state.redirect) {
      return <Navigate replace={true} to="/events"/>;
    }

    if (!this.state.event) {
      return null;
    }
    const { event } = this.state;

    const eventTypeOptions = Object.values(EventType).map((eventType) => {
      return {
        key: eventType,
        value: eventType,
        text: LocalizedUtils.getLocalizedEventType(eventType as EventType)
      };
    }); 

    const batchesOptions: DropdownItemProps[] = (this.state.batches ?? []).map((batch) => {
      const id = batch.id || "";
      const name = batch.batchNumber || "";

      return {
        key: id,
        value: id,
        text: name
      };
    });

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={6}>
            <h2>{strings.addEventHeader}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
          <FormContainer>
            <Form.Select required label={strings.labelEventType} name="type" options={eventTypeOptions} value={event.type || ""} onChange={this.handleBaseChange} />
              {event.type !== EventType.Sowing &&
              <Form.Field required>
                <label>{strings.labelBatchNumber}</label>
                <Select name="batchId" options={batchesOptions} onChange={this.handleBaseChange} value={event.batchId} />
              </Form.Field>
              }
              <Form.Field required>
                <label>{strings.labelStartTime}</label>
                <DateTimeInput localization="fi-FI" dateTimeFormat="DD.MM.YYYY HH:mm" onChange={this.handleTimeChange} name="startTime" value={moment(event.startTime).format("DD.MM.YYYY HH:mm")} />
              </Form.Field>
              <Form.Field>
                <label>{strings.labelEndTime}</label>
                <DateTimeInput localization="fi-FI" dateTimeFormat="DD.MM.YYYY HH:mm" onChange={this.handleTimeChange} name="endTime" value={moment(event.endTime).format("DD.MM.YYYY HH:mm")} />
              </Form.Field>
              {this.renderEventDataForm(event)}
              <Form.TextArea label={strings.labelAdditionalInformation} onChange={this.handleBaseChange} name="additionalInformation" value={event.additionalInformation} />
              <Message
                success
                visible={this.state.messageVisible}
                header={strings.savedSuccessfully}
              />
              <Button
                className="submit-button"
                onClick={this.handleSubmit}
                type='submit'
                loading={this.state.saving}
              >
                {strings.save}
              </Button>
              <Button onClick={() => this.setState({redirect: true})}>
                {strings.goBack}
              </Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
        <Confirm open={this.state.open} size={"mini"} content={strings.deleteEventConfirmText} onCancel={()=>this.setState({open:false})} onConfirm={this.handleDelete} />
      </Grid>
    );
  }

    /**
   * Handle value change
   *
   * @param event event
   */
  private handleTimeChange = (e: any, { name, value }: DropdownProps | TextAreaProps) => {
    const eventData: any = this.state.event;
    if (!eventData) {
      return;
    }

    eventData[name] = moment(value as any, "DD.MM.YYYY HH:mm").toDate()
    this.setState({ event: eventData });
  }

  /**
   * Handle value change
   *
   * @param event event
   */
  private handleBaseChange = (e: any, { name, value }: DropdownProps | TextAreaProps) => {
    const eventData: any = this.state.event;
    if (!eventData) {
      return;
    }

    eventData[name] = value;
    this.setState({ event: { ...eventData } });
  }

  /**
   * Handle value change
   *
   * @param event event
   */
  private handleDataChange = (e: any, { name, value }: InputOnChangeData | DropdownProps | TextAreaProps) => {
    const eventData = {...this.state.event} as any;
    if (!eventData) {
      return;
    }

    eventData.data = {...this.state.event!.data};
    eventData.data[name] = value;
    this.setState({ event: { ...eventData } });
  }

  /**
   * Handle form submit
   */
  private handleSubmit = async () => {
    const { event } = this.state;
    const { keycloak, facility, onError } = this.props;
    try {
      if (!keycloak || !event) {
        return;
      }

      this.setState({saving: true});
      const batchesService = await Api.getBatchesService(keycloak);
      const eventsService = await Api.getEventsService(keycloak);

      if (event.type === EventType.Sowing) {
        const eventData = event.data as SowingEventData;
        const batch = await batchesService.createBatch({
          batch: {
            batchNumber: this.getNextBatchNumber(),
            amount: eventData.amount,
            status: BatchStatus.Sowing
          },
          facility: facility
        });
        event.batchId = batch.id!;
      }

      await eventsService.createEvent({
        event: event as Event,
        facility: facility
      });
      this.setState({saving: false, messageVisible: true});
      setTimeout(() => {
        this.setState({messageVisible: false});
      }, 3000);
    } catch (e: any) {
      console.error(e);
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Get batch number
   * 
   * @returns batchNumber
   */
  private getNextBatchNumber = () => {
    const selectedYear = this.state.event.startTime ? DateTime.fromJSDate(this.state.event.startTime).year : DateTime.now().year;
  
    const batches = this.state.batches || [];

    const nextBatch = batches
    .filter((batch) => batch.batchNumber?.includes(`${selectedYear}/`))
      .map((batch) => {
        return parseInt(batch.batchNumber?.split("/")[1] || "0", 10);
      })
      .reduce((a, b) => Math.max(a, b), 0) + 1;

    // Pad the batch number with leading zeros if necessary and return the formatted string
    const nextBatchPadded = String(nextBatch).padStart(3, '0');
    
    return `${selectedYear}/${nextBatchPadded}`;
  };

  /**
   * Handle product delete
   */
  private handleDelete = async () => {
    const { event } = this.state;
    const { keycloak, facility, onError } = this.props;
    try {
      if (!keycloak || !event) {
        return;
      }

      const eventsService = await Api.getEventsService(keycloak);
      const id = event.id || "";

      await eventsService.deleteEvent({
        eventId: id,
        facility: facility
      });

      this.setState({redirect: true});
    } catch (e: any) {
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Renders form suitable for specific event type
   * 
   * @param event event
   */
  private renderEventDataForm = (event: Partial<Event>) => {
    switch (event.type) {
      case "TRANSFER":
        return this.renderTransferDataForm(event.data as TransferEventData);
      case "SOWING":
        return this.renderSowingDataForm(event.data as SowingEventData);
      case "WASTAGE":
        return this.renderWastageDataForm(event.data as WastageEventData);
      case "PERFORMED_ACTION":
        return this.renderPerformedActionDataForm(event.data as PerformedActionEventData);
      case "LOAD":
        return this.renderLoadDataForm(event.data as LoadEventData);
      case "PACKING":
        return this.renderPackingDataForm(event.data as PackingEventData);
      default:
        return null;
    }
  }

  /**
   * Renders trasfer event form
   * 
   * @param data transfer event data
   */
  private renderTransferDataForm = (data: TransferEventData) => {
    return (
      <React.Fragment>
        <Form.Select
         required label={strings.sourceLocation} name="sourceLocationId" type="sourceLocationId" options={this.getLocationOptions()} value={data.sourceLocationId} onChange={this.handleDataChange} />
        <Form.Select
         required label={strings.targetLocation} name="targetLocationId" type="targetLocationId" options={this.getLocationOptions()} value={data.targetLocationId} onChange={this.handleDataChange} />
        <Form.Input  required label={strings.amount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders performed action event form
   * 
   * @param data performed action event data
   */
  private renderPerformedActionDataForm = (data: PerformedActionEventData) => {
    const performedActionOptions = (this.state.performedCultivationActions ?? []).map((action) => {
      return {
        key: action.id,
        value: action.id,
        text: LocalizedUtils.getLocalizedValue(action.name)
      };
    });

    return (
      <React.Fragment>
        <Form.Select required label={strings.labelPerformedCultivationActions} options={ performedActionOptions } name="cultivationActionId" value={ data.cultivationActionId} onChange={ this.handleDataChange }/>
        <Form.Input required label={strings.labelAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="value" type="number" value={data.value} onChange={this.handleDataChange} />
        <Form.TextArea required label={strings.labelActionAdditionalInformation} onChange={this.handleDataChange} name="notes" value={data.notes} />
      </React.Fragment>
    );
  }

  /**
   * Renders loading event form
   * 
   * @param data loading event data
   */
  private renderLoadDataForm = (data: LoadEventData) => {
    return (
      <React.Fragment>
        <Form.Select label={strings.targetLocation} name="sourceLocationId" options={this.getLocationOptions()} value={data.sourceLocationId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelDriver} name="driver" type="text" value={data.driver} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders packing event form
   * 
   * @param data packing event data
   */
  private renderPackingDataForm = (data: PackingEventData) => {
    const containerTypeOptions = Object.values(PackingContainerType).map((containerType) => {
      return {
        key: containerType,
        value: containerType,
        text: LocalizedUtils.getLocalizedContainerType(containerType)
      };
    });

    const packingTypeOptions = Object.values(PackingType).map((packingType) => {
      return {
        key: packingType,
        value: packingType,
        text: LocalizedUtils.getLocalizedPackingType(packingType)
      };
    });

    return (
      <React.Fragment>
        <Form.Select required label={strings.sourceLocation} name="sourceLocationId" options={this.getLocationOptions()} value={data.sourceLocationId} onChange={this.handleDataChange} />
        <Form.Select required label={strings.targetLocation} name="targetLocationId" options={this.getLocationOptions()} value={data.targetLocationId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="largeTrayAmount" type="number" value={data.largeTrayAmount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelContainerType} name="containerType" options={containerTypeOptions} value={data.containerType} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="boxAmount" type="number" value={data.boxAmount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelPackingType} name="packingType" options={packingTypeOptions} value={data.packingType} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelPersonCount} name="personCount" type="number" value={data.personCount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders sowing event form
   * 
   * @param data sowing event data
   */
  private renderSowingDataForm = (data: SowingEventData) => {
    if (!this.state.seedBatches) {
      this.loadSowingData().catch((e) => {
        this.props.onError({
          message: strings.defaultApiErrorMessage,
          title: strings.defaultApiErrorTitle,
          exception: e
        });
      });

      return;
    }
    const seedBatchOptions = this.state.seedBatches.map((seedBatch) => {
      return {
        key: seedBatch.id,
        value: seedBatch.id,
        text: seedBatch.svCode
      };
    });
    const gritBatchOptions = this.state.gritBatches.map((batch) => {
      return {
        key: batch.id,
        value: batch.id,
        text: batch.batchNumber
      };
    });
    const peatBatchOptions = this.state.peatBatches.map((batch) => {
      return {
        key: batch.id,
        value: batch.id,
        text: batch.batchNumber
      };
    });

    return (
      <React.Fragment>
        <Form.Input required label={strings.cellAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelSeedBatch} name="seedBatchId" options={seedBatchOptions} value={data.seedBatchId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.amount} name="seedAmount" type="number" value={data.seedAmount} onChange={this.handleDataChange} />
        <Form.Input required label={strings.timber} name="woodType" type="text" value={data.woodType} onChange={this.handleDataChange} />
        <Form.Select required label={strings.location} name="locationId" options={this.getLocationOptions()} value={data.locationId} onChange={this.handleDataChange} />
        <Form.Select required label={strings.gritBatches} name="gritBatchId" options={gritBatchOptions} value={data.gritBatchId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.amount} name="gritAmount" type="number" value={data.gritAmount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.peatBatches} name="peatBatchId" options={peatBatchOptions} value={data.peatBatchId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.amount} name="peatAmount" type="number" value={data.peatAmount} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelPersonCount} name="amountOfPersons" type="number" value={data.amountOfPersons} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders wastage event form
   * 
   * @param data wastage event data
   */
  private renderWastageDataForm = (data: WastageEventData) => {
    if (!this.state.wastageReasons) {
      this.loadWastageData().catch((e) => {
        this.props.onError({
          message: strings.defaultApiErrorMessage,
          title: strings.defaultApiErrorTitle,
          exception: e
        });
      });

      return;
    }

    const wastageReasonOptions = this.state.wastageReasons.map((wastageReason) => {
      return {
        key: wastageReason.id,
        value: wastageReason.id,
        text: LocalizedUtils.getLocalizedValue(wastageReason.reason)
      };
    });

    const phaseOptions = Object.values(EventType).map((phase) => {
      return {
        key: phase,
        value: phase,
        text: LocalizedUtils.getLocalizedEventType(phase as EventType)
      };
    })

    return (
      <React.Fragment>
        <Form.Select required label={strings.labelWastageReason} name="reasonId" options={wastageReasonOptions} value={data.reasonId} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelPhase} name="phase" options={phaseOptions} value={data.phase} onChange={this.handleDataChange} />
        <Form.Select label={strings.labelLocation} name="locationId" options={this.getLocationOptions()} value={data.locationId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Get location options
   * 
   * @returns location options
   */
  private getLocationOptions = () => {
    return this.state.locations.map((location) => {
      return {
        key: location.id,
        value: location.id,
        text: location.name
      };
    });
  }

  /**
   * Loads data required for sowing event
   */
  private loadSowingData = async () => {
    const { keycloak, facility } = this.props;
    if (!keycloak) {
      return;
    }

    this.setState({loading: true});
    const [seedBatchesService] = await Promise.all([
      Api.getSeedBatchesService(keycloak),
      Api.getLocationsService(keycloak)
    ]);

    const [seedBatches] = await Promise.all([
      seedBatchesService.listSeedBatches({ facility: facility }),
    ]);

    this.setState({
      loading: false,
      seedBatches: seedBatches,
    });

  }

  /**
   * Loads data required for wastage event
   */
  private loadWastageData = async () => {
    const { keycloak, facility } = this.props;
    if (!keycloak) {
      return;
    }

    this.setState({loading: true});
    const [wastageReasonsService] = await Promise.all([
      Api.getWastageReasonsService(keycloak),
      Api.getLocationsService(keycloak)
    ]);

    const [wastageReasons] = await Promise.all([
      wastageReasonsService.listWastageReasons({ facility: facility }),
    ]);

    this.setState({
      loading: false,
      wastageReasons: wastageReasons,
    });

  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
     onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);