/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface CultivationAction
 */
export interface CultivationAction {
    /**
     * 
     * @type {string}
     * @memberof CultivationAction
     */
    id?: string;
    /**
     * 
     * @type {Array<LocalizedValue>}
     * @memberof CultivationAction
     */
    name?: Array<LocalizedValue>;
}

export function CultivationActionFromJSON(json: any): CultivationAction {
    return CultivationActionFromJSONTyped(json, false);
}

export function CultivationActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CultivationAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(LocalizedValueFromJSON)),
    };
}

export function CultivationActionToJSON(value?: CultivationAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(LocalizedValueToJSON)),
    };
}


