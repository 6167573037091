import * as React from "react";
import Keycloak from "keycloak-js";
import * as actions from "../actions";
import { StoreState } from "../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Route, NavLink, Routes } from "react-router-dom";
import Root from "./Root";
import strings from "../localization/strings";

import { Grid, Loader, Menu } from "semantic-ui-react";

import BasicLayout from "./BasicLayout";
import CreateLocation from "./location/CreateLocation";
import EditEvent from "./event/EditEvent";
import ReportDownload from "./ReportDownload";
import EditProductInfo from "./productInfo/EditProductInfo";
import LocationsList from "./location/LocationsList";
import EditLocation from "./location/EditLocation";
import EditSeedBatch from "./seedBatch/EditSeedBatch";
import CreateSeedBatch from "./seedBatch/CreateSeedBatch";
import CultivationActionList from "./cultivationActions/CultivationActionList";
import CreateCultivationAction from "./cultivationActions/CreateCultivationAction";
import EditCultivationAction from "./cultivationActions/EditCultivationAction";
import EditWastageReason from "./wastageReason/EditWastageReason";
import WastageReasonList from "./wastageReason/WastageReasonList";
import CreateWastageReason from "./wastageReason/CreateWastageReason";
import CreateEvent from "./event/CreateEvent";
import EventList from "./event/EventList";
import TreeSpecies from "./treeSpecies/TreeSpeciesList";
import { WithParams } from "./WithParams";
import { Facility } from "../generated/client";
import SeedBatchList from "./seedBatch/SeedBatchList";
import EditTreeSpecies from "./treeSpecies/EditTreeSpecies";
import CreateTreeSpecies from "./treeSpecies/CreateTreeSpecies";
import CreatePeatGritBatch from "./peatGritBatches/CreatePeatGritBatch";
import PeatGritBatchList from "./peatGritBatches/PeatGritBatchList";
import EditGritBatch from "./peatGritBatches/EditGritBatch";
import EditPeatBatch from "./peatGritBatches/EditPeatBatch";
import ProductInfoList from "./productInfo/ProductInfoList";
import CreateProductInfo from "./productInfo/CreateProductInfo";

export interface Props {
  authenticated: boolean;
  keycloak?: Keycloak;
  facility: Facility;
  onLogin?: (keycloak: Keycloak, authenticated: boolean) => void;
  onFacilityUpdate: (facility: Facility) => void;
  onLocaleUpdate: (locale: string) => void;
  locale: string;
}

class WelcomePage extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      keycloak: null,
    };
  }

  /**
   * Component did mount life-cycle event
   */
  componentDidMount = async () => {
    const { onFacilityUpdate, onLogin, onLocaleUpdate, locale } = this.props;
    const kcConf = {
      realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
      url: process.env.REACT_APP_AUTH_SERVER_URL as string,
      clientId: process.env.REACT_APP_AUTH_RESOURCE as string,
    };
    const keycloak = new Keycloak(kcConf);
    const authenticated = await keycloak.init({ onLoad: "login-required", checkLoginIframe: false });

    if (onLogin) {
      onLogin(keycloak, authenticated);
    }

    const previousFacility = window.localStorage.getItem("facility")
      ? (window.localStorage.getItem("facility") as Facility) || Facility.Joroinen
      : Facility.Joroinen;

    if (keycloak.hasRealmRole("joroinen")) {
      onFacilityUpdate(previousFacility);
      strings.setLanguage(`${locale.slice(0, 2)}_${previousFacility.toLowerCase()}`);
      onLocaleUpdate(`${locale.slice(0, 2)}_${previousFacility.toLowerCase()}`);
    } else {
      const userFacility = Facility.Joroinen;
      onFacilityUpdate(userFacility);
      strings.setLanguage(`${locale.slice(0, 2)}_${userFacility.toLowerCase()}`);
      onLocaleUpdate(`${locale.slice(0, 2)}_${userFacility.toLowerCase()}`);
    }

    this.setState({ keycloak: keycloak });
  };

  /**
   * Render welcome page view
   */
  public render() {
    const navigationRoutes = [
      {
        text: strings.events,
        route: "/events",
      },
      {
        text: strings.performedCultivationActions,
        route: "/performedCultivationActions",
      },
      {
        text: strings.wastageReasons,
        route: "/wastageReasons",
      },
      {
        text: strings.locations,
        route: "/locations",
      },
      {
        text: strings.productInfos,
        route: "/productInfos",
      },
      {
        text: strings.treeSpecies,
        route: "/treeSpecies",
      },
      {
        text: strings.materials,
        route: "/peatGritBatches",
      },
      {
        text: strings.seedBatches,
        route: "/seedBatches",
      },
      {
        text: strings.reportDownloadHeader,
        route: "/reports",
      },
      {
        text: strings.userManagementLink,
        route: process.env.REACT_APP_ACCOUNT_MANAGEMENT_URL,
        external: true,
      },
    ];

    const sideBarNavigation = navigationRoutes.map((navigationRoute, index) => {
      const itemParams = navigationRoute.external
        ? {
            href: navigationRoute.route,
            target: "blank",
            as: "a",
          }
        : {
            to: navigationRoute.route,
            as: NavLink,
          };

      return (
        <Menu.Item key={index} {...itemParams}>
          {navigationRoute.text}
        </Menu.Item>
      );
    });

    const appContent = (
      <BasicLayout sidebarItems={sideBarNavigation}>
        {!this.props.authenticated || !this.state.keycloak ? (
          <div>
            <Grid centered>
              <Loader active size="medium" />
            </Grid>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Root keycloak={this.state.keycloak} />} />
            <Route path="/events" element={<EventList keycloak={this.state.keycloak} />} />
            <Route path="/treeSpecies" element={<TreeSpecies keycloak={this.state.keycloak} />} />

            <Route
              path="/treeSpecies/:treeSpeciesId"
              element={
                <WithParams Component={EditTreeSpecies} keycloak={this.state.keycloak} routeParamNames={["treeSpeciesId"]} />
              }
            />
            <Route path="/productInfos" element={<ProductInfoList keycloak={this.state.keycloak} />} />
            <Route
              path="/productInfos/:productInfoId"
              element={
                <WithParams Component={EditProductInfo} keycloak={this.state.keycloak} routeParamNames={["productInfoId"]} />
              }
            />
            <Route
              path="/events/:eventId"
              element={
                <WithParams Component={EditEvent} keycloak={this.state.keycloak} routeParamNames={["eventId"]} />
              }
            />
            <Route path="/createTreeSpecies" element={<CreateTreeSpecies keycloak={this.state.keycloak} />} />
            <Route path="/createEvent" element={<CreateEvent keycloak={this.state.keycloak} />} />
            <Route path="/createProductInfo" element={<CreateProductInfo keycloak={this.state.keycloak} />} />

            <Route path="/locations" element={<LocationsList keycloak={this.state.keycloak} />} />
            <Route
              path="/locations/:locationId"
              element={
                <WithParams
                  Component={EditLocation}
                  keycloak={this.state.keycloak}
                  routeParamNames={["locationId"]}
                />
              }
            />
            <Route path="/createLocation" element={<CreateLocation keycloak={this.state.keycloak} />} />
            <Route path="/peatGritBatches" element={<PeatGritBatchList keycloak={this.state.keycloak} />} />
            <Route
              path="/gritBatches/:gritBatchId"
              element={<WithParams Component={EditGritBatch} keycloak={this.state.keycloak} routeParamNames={["gritBatchId"]} />}
            />
            <Route
              path="/peatBatches/:peatBatchId"
              element={<WithParams Component={EditPeatBatch} keycloak={this.state.keycloak} routeParamNames={["peatBatchId"]} />}
            />
            <Route path="/createPeatGritBatch" element={<CreatePeatGritBatch keycloak={this.state.keycloak} />} />
            <Route path="/createSeedBatch" element={<CreateSeedBatch keycloak={this.state.keycloak} />} />
            <Route path="/seedBatches" element={<SeedBatchList keycloak={this.state.keycloak} />} />
            <Route
              path="/seedBatches/:seedBatchId"
              element={<WithParams Component={EditSeedBatch} keycloak={this.state.keycloak} routeParamNames={["seedBatchId"]} />}
            />
            <Route
              path="/performedCultivationActions"
              element={<CultivationActionList keycloak={this.state.keycloak} />}
            />
            <Route
              path="/createPerformedCultivationAction"
              element={<CreateCultivationAction keycloak={this.state.keycloak} />}
            />
            <Route
              path="/performedCultivationActions/:performedCultivationActionId"
              element={
                <WithParams
                  Component={EditCultivationAction}
                  keycloak={this.state.keycloak}
                  routeParamNames={["performedCultivationActionId"]}
                />
              }
            />
            <Route path="/wastageReasons" element={<WastageReasonList keycloak={this.state.keycloak} />} />
            <Route
              path="/wastageReasons/:wastageReasonId"
              element={
                <WithParams
                  Component={EditWastageReason}
                  keycloak={this.state.keycloak}
                  routeParamNames={["wastageReasonId"]}
                />
              }
            />
            <Route
              path="/createWastageReason"
              element={<CreateWastageReason keycloak={this.state.keycloak} facility={this.props.facility} />}
            />
            <Route path="/reports" element={<ReportDownload keycloak={this.state.keycloak} />} />
          </Routes>
        )}
      </BasicLayout>
    );

    return <div>{appContent}</div>;
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    authenticated: state.authenticated,
    keycloak: state.keycloak,
    locale: state.locale,
    facility: state.facility,
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onLogin: (keycloak: Keycloak, authenticated: boolean) => dispatch(actions.userLogin(keycloak, authenticated)),
    onFacilityUpdate: (facility: Facility) => dispatch(actions.facilityUpdate(facility)),
    onLocaleUpdate: (locale: string) => dispatch(actions.localeUpdate(locale)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
