/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id?: string;
    /**
     * 
     * @type {Array<LocalizedValue>}
     * @memberof Product
     */
    name?: Array<LocalizedValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    defaultPackageSizeIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isSubcontractorProduct: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isEndProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isRawMaterial?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    salesWeight?: number;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(LocalizedValueFromJSON)),
        'defaultPackageSizeIds': !exists(json, 'defaultPackageSizeIds') ? undefined : json['defaultPackageSizeIds'],
        'isSubcontractorProduct': json['isSubcontractorProduct'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'isEndProduct': !exists(json, 'isEndProduct') ? undefined : json['isEndProduct'],
        'isRawMaterial': !exists(json, 'isRawMaterial') ? undefined : json['isRawMaterial'],
        'salesWeight': !exists(json, 'salesWeight') ? undefined : json['salesWeight'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(LocalizedValueToJSON)),
        'defaultPackageSizeIds': value.defaultPackageSizeIds,
        'isSubcontractorProduct': value.isSubcontractorProduct,
        'active': value.active,
        'isEndProduct': value.isEndProduct,
        'isRawMaterial': value.isRawMaterial,
        'salesWeight': value.salesWeight,
    };
}


