/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    ProductInfo,
    ProductInfoFromJSON,
    ProductInfoToJSON,
} from '../models';

export interface CreateProductInfoRequest {
    productInfo: ProductInfo;
    facility: Facility;
}

export interface DeleteProductInfoRequest {
    facility: Facility;
    productInfoId: string;
}

export interface FindProductInfoRequest {
    facility: Facility;
    productInfoId: string;
}

export interface ListProductInfoRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdateProductInfoRequest {
    productInfo: ProductInfo;
    facility: Facility;
    productInfoId: string;
}

/**
 * 
 */
export class ProductInfoApi extends runtime.BaseAPI {

    /**
     * Create new ProductInfo
     */
    async createProductInfoRaw(requestParameters: CreateProductInfoRequest): Promise<runtime.ApiResponse<ProductInfo>> {
        if (requestParameters.productInfo === null || requestParameters.productInfo === undefined) {
            throw new runtime.RequiredError('productInfo','Required parameter requestParameters.productInfo was null or undefined when calling createProductInfo.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createProductInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productInfo`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductInfoToJSON(requestParameters.productInfo),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductInfoFromJSON(jsonValue));
    }

    /**
     * Create new ProductInfo
     */
    async createProductInfo(requestParameters: CreateProductInfoRequest): Promise<ProductInfo> {
        const response = await this.createProductInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a product information record
     */
    async deleteProductInfoRaw(requestParameters: DeleteProductInfoRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteProductInfo.');
        }

        if (requestParameters.productInfoId === null || requestParameters.productInfoId === undefined) {
            throw new runtime.RequiredError('productInfoId','Required parameter requestParameters.productInfoId was null or undefined when calling deleteProductInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productInfo/{productInfoId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"productInfoId"}}`, encodeURIComponent(String(requestParameters.productInfoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a product information record
     */
    async deleteProductInfo(requestParameters: DeleteProductInfoRequest): Promise<void> {
        await this.deleteProductInfoRaw(requestParameters);
    }

    /**
     * Find a product information record
     */
    async findProductInfoRaw(requestParameters: FindProductInfoRequest): Promise<runtime.ApiResponse<ProductInfo>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findProductInfo.');
        }

        if (requestParameters.productInfoId === null || requestParameters.productInfoId === undefined) {
            throw new runtime.RequiredError('productInfoId','Required parameter requestParameters.productInfoId was null or undefined when calling findProductInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productInfo/{productInfoId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"productInfoId"}}`, encodeURIComponent(String(requestParameters.productInfoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductInfoFromJSON(jsonValue));
    }

    /**
     * Find a product information record
     */
    async findProductInfo(requestParameters: FindProductInfoRequest): Promise<ProductInfo> {
        const response = await this.findProductInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all product information records
     */
    async listProductInfoRaw(requestParameters: ListProductInfoRequest): Promise<runtime.ApiResponse<Array<ProductInfo>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listProductInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productInfo`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductInfoFromJSON));
    }

    /**
     * List all product information records
     */
    async listProductInfo(requestParameters: ListProductInfoRequest): Promise<Array<ProductInfo>> {
        const response = await this.listProductInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a product information record
     */
    async updateProductInfoRaw(requestParameters: UpdateProductInfoRequest): Promise<runtime.ApiResponse<ProductInfo>> {
        if (requestParameters.productInfo === null || requestParameters.productInfo === undefined) {
            throw new runtime.RequiredError('productInfo','Required parameter requestParameters.productInfo was null or undefined when calling updateProductInfo.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateProductInfo.');
        }

        if (requestParameters.productInfoId === null || requestParameters.productInfoId === undefined) {
            throw new runtime.RequiredError('productInfoId','Required parameter requestParameters.productInfoId was null or undefined when calling updateProductInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productInfo/{productInfoId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"productInfoId"}}`, encodeURIComponent(String(requestParameters.productInfoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductInfoToJSON(requestParameters.productInfo),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductInfoFromJSON(jsonValue));
    }

    /**
     * Updates a product information record
     */
    async updateProductInfo(requestParameters: UpdateProductInfoRequest): Promise<ProductInfo> {
        const response = await this.updateProductInfoRaw(requestParameters);
        return await response.value();
    }

}
