import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { Facility, TreeSpecies, ProductInfo } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";

import {
  Grid,
  Button,
  Form,
  DropdownProps,
  InputOnChangeData
} from "semantic-ui-react";
import { FormContainer } from "../FormContainer";

/**
 * Component props
 */
interface Props {
  keycloak?: Keycloak;
  facility: Facility;
  onProductInfoCreated?: (productInfo: ProductInfo) => void;
  onTreeSpeciesFound?: (treeSpecies: TreeSpecies[]) => void;
  onError: (error: ErrorMessage | undefined) => void;
}

/**
 * Component state
 */
interface State {
  productInfo: ProductInfo,
  redirect: boolean;
  treeSpecies?: TreeSpecies[];
}

/**
 * Create product info screen
 */
class CreateProductInfo extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      productInfo: {
        bags: 0,
        boxes: 0,
        cells: 0,
        platforms: 0,
        treeSpeciesId: ""
      }
    };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    const { keycloak, facility, onError, onTreeSpeciesFound } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      const getTreeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      const treeSpecies = await getTreeSpeciesService.listTreeSpecies({ facility: facility });
      onTreeSpeciesFound && onTreeSpeciesFound(treeSpecies);
      this.setState({ treeSpecies: treeSpecies });
    } catch (e: any) {
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Handle form submit
   */
  private handleSubmit = async () => {
    const { keycloak, facility } = this.props;
    const { productInfo } = this.state;

    if (!keycloak) {
      return;
    }

    try {
      const productInfoService = await Api.getProductInfoService(keycloak);
      await productInfoService.createProductInfo({
        facility: facility,
        productInfo: productInfo
      });

      this.setState({ redirect: true });
    } catch (e: any) {
      this.props.onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Handle tree species select event change
   *
   * @param e event
   * @param value updated tree species
   */
  private onTreeSpeciesChange = (e: any, { value }: DropdownProps) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        treeSpeciesId: value as string
      }
    });
  }

  /**
   * Handle cells event change
   *
   * @param e event
   * @param value cells
   */
  private onCellsChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        cells: Number.parseInt(value)
      }
    });
  }

  /**
   * Handle boxes event change
   *
   * @param e event
   * @param value boxes
   */
  private onBoxesChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        boxes: Number.parseInt(value)
      }
    });
  }

  /**
   * Handle platform event change
   *
   * @param e event
   * @param value platform
   */
  private onPlatformsChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        platforms: Number.parseInt(value)
      }
    });
  }

    /**
   * Handle bags event change
   *
   * @param e event
   * @param value bags
   */
  private onBagsChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        bags: Number.parseInt(value)
      }
    });
  }

  /**
   * Render product create view
   */
  public render = () => {
    const { treeSpecies, productInfo } = this.state;

    if (this.state.redirect) {
      return <Navigate replace={true} to="/productInfos"/>;
    }

    const treeSpeciesOptions = (treeSpecies || []).map((tree) => {
      return {
        key: tree.id,
        text: tree.name![0].value,
        value: tree.id
      };
    });

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={ 8 }>
            <h2>{ strings.newProduct }</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={ 8 }>
            <FormContainer>
              <Form.Select
                fluid
                required
                label={ strings.treeSpecies }
                options={ treeSpeciesOptions }
                value={ productInfo.treeSpeciesId }
                onChange={ this.onTreeSpeciesChange }
              />
              <Form.Input
                required
                label={ strings.boxes }
                name="boxes"
                type="number"
                value={ productInfo.boxes }
                onChange={ this.onBoxesChange }
              />
              <Form.Input
                required
                label={ strings.bags }
                name="bags"
                type="number"
                value={ productInfo.bags }
                onChange={ this.onBagsChange }
              />
              <Form.Input
                required
                label={ strings.cells }
                name="cells"
                type="number"
                value={ productInfo.cells }
                onChange={ this.onCellsChange }
              />
              <Form.Input
                required
                label={ strings.treeSpeciesPlatforms }
                name="platforms"
                type="number"
                value={ productInfo.platforms }
                onChange={ this.onPlatformsChange }
              />
              <Button
                className="submit-button"
                onClick={ this.handleSubmit }
                type="submit"
              >
                { strings.save }
              </Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: StoreState) => ({
  productInfos: state.productInfos,
  productInfo: state.productInfo,
  treeSpecies: state.treeSpecies,
  facility: state.facility
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<actions.AppAction>) => ({
  onProductInfoCreated: (productInfo: ProductInfo) => dispatch(actions.productInfoCreated(productInfo)),
  onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
  onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductInfo);