/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    TreeSpecies,
    TreeSpeciesFromJSON,
    TreeSpeciesToJSON,
} from '../models';

export interface CreateTreeSpeciesRequest {
    treeSpecies: TreeSpecies;
    facility: Facility;
}

export interface DeleteTreeSpeciesRequest {
    facility: Facility;
    treeSpeciesId: string;
}

export interface FindTreeSpeciesRequest {
    facility: Facility;
    treeSpeciesId: string;
}

export interface ListTreeSpeciesRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdateTreeSpeciesRequest {
    treeSpecies: TreeSpecies;
    facility: Facility;
    treeSpeciesId: string;
}

/**
 * 
 */
export class TreeSpeciesApi extends runtime.BaseAPI {

    /**
     * Create new tree species
     */
    async createTreeSpeciesRaw(requestParameters: CreateTreeSpeciesRequest): Promise<runtime.ApiResponse<TreeSpecies>> {
        if (requestParameters.treeSpecies === null || requestParameters.treeSpecies === undefined) {
            throw new runtime.RequiredError('treeSpecies','Required parameter requestParameters.treeSpecies was null or undefined when calling createTreeSpecies.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createTreeSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/treeSpecies`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TreeSpeciesToJSON(requestParameters.treeSpecies),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeSpeciesFromJSON(jsonValue));
    }

    /**
     * Create new tree species
     */
    async createTreeSpecies(requestParameters: CreateTreeSpeciesRequest): Promise<TreeSpecies> {
        const response = await this.createTreeSpeciesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a tree species
     */
    async deleteTreeSpeciesRaw(requestParameters: DeleteTreeSpeciesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteTreeSpecies.');
        }

        if (requestParameters.treeSpeciesId === null || requestParameters.treeSpeciesId === undefined) {
            throw new runtime.RequiredError('treeSpeciesId','Required parameter requestParameters.treeSpeciesId was null or undefined when calling deleteTreeSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/treeSpecies/{treeSpeciesId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"treeSpeciesId"}}`, encodeURIComponent(String(requestParameters.treeSpeciesId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a tree species
     */
    async deleteTreeSpecies(requestParameters: DeleteTreeSpeciesRequest): Promise<void> {
        await this.deleteTreeSpeciesRaw(requestParameters);
    }

    /**
     * Find a tree species
     */
    async findTreeSpeciesRaw(requestParameters: FindTreeSpeciesRequest): Promise<runtime.ApiResponse<TreeSpecies>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findTreeSpecies.');
        }

        if (requestParameters.treeSpeciesId === null || requestParameters.treeSpeciesId === undefined) {
            throw new runtime.RequiredError('treeSpeciesId','Required parameter requestParameters.treeSpeciesId was null or undefined when calling findTreeSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/treeSpecies/{treeSpeciesId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"treeSpeciesId"}}`, encodeURIComponent(String(requestParameters.treeSpeciesId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeSpeciesFromJSON(jsonValue));
    }

    /**
     * Find a tree species
     */
    async findTreeSpecies(requestParameters: FindTreeSpeciesRequest): Promise<TreeSpecies> {
        const response = await this.findTreeSpeciesRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all tree species
     */
    async listTreeSpeciesRaw(requestParameters: ListTreeSpeciesRequest): Promise<runtime.ApiResponse<Array<TreeSpecies>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listTreeSpecies.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/treeSpecies`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TreeSpeciesFromJSON));
    }

    /**
     * List all tree species
     */
    async listTreeSpecies(requestParameters: ListTreeSpeciesRequest): Promise<Array<TreeSpecies>> {
        const response = await this.listTreeSpeciesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a tree species
     */
    async updateTreeSpeciesRaw(requestParameters: UpdateTreeSpeciesRequest): Promise<runtime.ApiResponse<TreeSpecies>> {
        if (requestParameters.treeSpecies === null || requestParameters.treeSpecies === undefined) {
            throw new runtime.RequiredError('treeSpecies','Required parameter requestParameters.treeSpecies was null or undefined when calling updateTreeSpecies.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateTreeSpecies.');
        }

        if (requestParameters.treeSpeciesId === null || requestParameters.treeSpeciesId === undefined) {
            throw new runtime.RequiredError('treeSpeciesId','Required parameter requestParameters.treeSpeciesId was null or undefined when calling updateTreeSpecies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/treeSpecies/{treeSpeciesId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"treeSpeciesId"}}`, encodeURIComponent(String(requestParameters.treeSpeciesId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TreeSpeciesToJSON(requestParameters.treeSpecies),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeSpeciesFromJSON(jsonValue));
    }

    /**
     * Updates a tree species
     */
    async updateTreeSpecies(requestParameters: UpdateTreeSpeciesRequest): Promise<TreeSpecies> {
        const response = await this.updateTreeSpeciesRaw(requestParameters);
        return await response.value();
    }

}
