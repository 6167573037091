import * as constants from '../constants'
import Keycloak from 'keycloak-js';
import { PackageSize, TreeSpecies, Location, SeedBatch, CultivationAction, WastageReason, Pest, Campaign, Event, Facility, PeatBatch, GritBatch, ProductInfo } from "../generated/client";
import { ErrorMessage, EventListFilters } from '../types';

export interface UserLogin {
  type: constants.USER_LOGIN;
  keycloak?: Keycloak;
  authenticated: boolean;
}

export interface UserLogout {
  type: constants.USER_LOGOUT;
}

export interface ProductInfosFound {
  type: constants.PRODUCT_INFOS_FOUND;
  productInfos: ProductInfo[];
}

export interface ProductInfoSelected {
  type: constants.PRODUCT_INFO_SELECTED;
  productInfo: ProductInfo;
}

export interface ProductInfoCreated {
  type: constants.PRODUCT_INFO_CREATED;
  productInfo: ProductInfo;
}

export interface ProductInfoDeleted {
  type: constants.PRODUCT_INFO_DELETED;
  productInfoId: string;
}

export interface PackageSizesFound {
  type: constants.PACKAGE_SIZES_FOUND;
  packageSizes: PackageSize[];
}

export interface PeatBatchesFound {
  type: constants.PEAT_BATCHES_FOUND;
  peatBatches: PeatBatch[];
}

export interface GritBatchesFound {
  type: constants.GRIT_BATCHES_FOUND;
  gritBatches: GritBatch[];
}

export interface PackageSizeSelected {
  type: constants.PACKAGE_SIZE_SELECTED;
  packageSize: PackageSize;
}

export interface PackageSizeCreated {
  type: constants.PACKAGE_SIZE_CREATED;
  packageSize: PackageSize;
}

export interface PackageSizeDeleted {
  type: constants.PACKAGE_SIZE_DELETED;
  packageSizeId: string;
}

export interface TreeSpeciesFound {
  type: constants.TREESPECIES_FOUND;
  treeSpecies: TreeSpecies[];
}

export interface TreeSpecieSelected {
  type: constants.TREESPECIES_SELECTED;
  treeSpecies: TreeSpecies;
}

export interface TreeSpeciesCreated {
  type: constants.TREESPECIES_CREATED;
  treeSpecies: TreeSpecies;
}

export interface TreeSpeciesDeleted {
  type: constants.TREESPECIES_DELETED;
  treeSpeciesId: string;
}

export interface LocationsFound {
  type: constants.LOCATIONS_FOUND;
  locations: Location[];
}

export interface LocationSelected {
  type: constants.LOCATION_SELECTED;
  location: Location;
}

export interface LocationCreated {
  type: constants.LOCATION_CREATED;
  location: Location;
}

export interface LocationDeleted {
  type: constants.LOCATION_DELETED;
  locationId: string;
}

export interface SeedBatchesFound {
  type: constants.SEED_BATCHES_FOUND;
  seedBatches: SeedBatch[];
}

export interface SeedBatchSelected {
  type: constants.SEED_BATCH_SELECTED;
  seedBatch: SeedBatch;
}

export interface SeedBatchCreated {
  type: constants.SEED_BATCH_CREATED;
  seedBatch: SeedBatch;
}

export interface SeedBatchDeleted {
  type: constants.SEED_BATCH_DELETED;
  seedBatchId: string;
}

export interface GritBatchDeleted {
  type: constants.GRIT_BATCH_DELETED;
  gritBatchId: string;
}

export interface PeatBatchDeleted {
  type: constants.PEAT_BATCH_DELETED;
  peatBatchId: string;
}

export interface GritBatchSelected {
  type: constants.GRIT_BATCH_SELECTED;
  gritBatch: GritBatch;
}

export interface PeatBatchSelected {
  type: constants.PEAT_BATCH_SELECTED;
  peatBatch: PeatBatch;
}

export interface PerformedCultivationActionsFound {
  type: constants.PERFORMED_CULTIVATION_ACTIONS_FOUND;
  performedCultivationActions: CultivationAction[];
}

export interface PerformedCultivationActionSelected {
  type: constants.PERFORMED_CULTIVATION_ACTION_SELECTED;
  performedCultivationAction: CultivationAction;
}

export interface PerformedCultivationActionCreated {
  type: constants.PERFORMED_CULTIVATION_ACTION_CREATED;
  performedCultivationAction: CultivationAction;
}

export interface PerformedCultivationActionDeleted {
  type: constants.PERFORMED_CULTIVATION_ACTION_DELETED;
  performedCultivationActionId: string;
}

/**
 * Locale update data
 */
export interface LocaleUpdate {
  type: constants.LOCALE_UPDATE,
  locale: string
}

/**
 * Facility update data
 */
 export interface FacilityUpdate {
  type: constants.FACILITY_UPDATE,
  facility: Facility
}

/**
 * Interface representing WastageReasonsFound action
 */
export interface WastageReasonsFound {
  type: constants.WASTAGE_REASONS_FOUND;
  wastageReasons: WastageReason[];
}

/**
 * Interface representing WastageReasonSelected action
 */
export interface WastageReasonSelected {
  type: constants.WASTAGE_REASON_SELECTED;
  wastageReason: WastageReason;
}

/**
 * Interface representing WastageReasonCreated action
 */
export interface WastageReasonCreated {
  type: constants.WASTAGE_REASON_CREATED;
  wastageReason: WastageReason;
}

/**
 * Interface representing WastageReasonDeleted action
 */
export interface WastageReasonDeleted {
  type: constants.WASTAGE_REASON_DELETED;
  wastageReasonId: string;
}

export interface PestsFound {
  type: constants.PESTS_FOUND;
  pests: Pest[];
}

/**
 * Interface representing ErrorOccurred action
 */
export interface ErrorOccurred {
  type: constants.ERROR_OCCURRED;
  error?: ErrorMessage;
}

/**
 * Interface representing CampaignsFound action
 */
export interface CampaignsFound {
  type: constants.CAMPAIGNS_FOUND;
  campaigns: Campaign[]
}

export interface EventListFiltersUpdated {
  type: constants.EVENT_LIST_FILTERS_UPDATED;
  eventListFilters: EventListFilters
}

export interface EventsFound {
  type: constants.EVENTS_FOUND;
  events: Event[];
}



export type AppAction = UserLogin | UserLogout | PackageSizesFound
  | PackageSizeSelected | PackageSizeCreated | PackageSizeDeleted | TreeSpeciesFound | TreeSpeciesCreated
  | TreeSpecieSelected | TreeSpeciesDeleted | LocationsFound | LocationSelected | LocationCreated
  | LocationDeleted | SeedBatchesFound | SeedBatchSelected | SeedBatchCreated | SeedBatchDeleted
  | PerformedCultivationActionsFound | PerformedCultivationActionSelected | PerformedCultivationActionCreated
  | PerformedCultivationActionDeleted | LocaleUpdate | WastageReasonsFound | WastageReasonSelected | EventsFound
  | WastageReasonCreated | WastageReasonDeleted| PestsFound | ErrorOccurred | CampaignsFound | EventListFiltersUpdated | FacilityUpdate
  | PeatBatchesFound | GritBatchesFound | GritBatchSelected | PeatBatchSelected | GritBatchDeleted | PeatBatchDeleted | ProductInfosFound
  | ProductInfoCreated | ProductInfoCreated | ProductInfoDeleted | ProductInfoSelected;

  /**
   * Redux store update method for campaigns
   * 
   * @param campaigns campaigns
   */
export function campaignsFound(campaigns: Campaign[]): CampaignsFound {
  return {
    type: constants.CAMPAIGNS_FOUND,
    campaigns
  }
}

export function userLogin(keycloak: Keycloak, authenticated: boolean): UserLogin {
  return {
    type: constants.USER_LOGIN,
    keycloak: keycloak,
    authenticated: authenticated
  }
}

export function userLogout(): UserLogout {
  return {
    type: constants.USER_LOGOUT
  }
}

export function productInfosFound(productInfos: ProductInfo[]): ProductInfosFound {
  return {
    type: constants.PRODUCT_INFOS_FOUND,
    productInfos: productInfos
  }
}

export function productInfoSelected(productInfo: ProductInfo): ProductInfoSelected {
  return {
    type: constants.PRODUCT_INFO_SELECTED,
    productInfo: productInfo
  }
}

export function productInfoCreated(productInfo: ProductInfo): ProductInfoCreated {
  return {
    type: constants.PRODUCT_INFO_CREATED,
    productInfo: productInfo
  }
}

export function productInfoDeleted(productInfoId: string): ProductInfoDeleted {
  return {
    type: constants.PRODUCT_INFO_DELETED,
    productInfoId: productInfoId
  }
}

export function packageSizesFound(packageSizes: PackageSize[]): PackageSizesFound {
  return {
    type: constants.PACKAGE_SIZES_FOUND,
    packageSizes: packageSizes
  }
}

export function peatBatchesFound(peatBatches: PeatBatch[]): PeatBatchesFound {
  return {
    type: constants.PEAT_BATCHES_FOUND,
    peatBatches: peatBatches
  }
}

export function gritBatchesFound(gritBatches: GritBatch[]): GritBatchesFound {
  return {
    type: constants.GRIT_BATCHES_FOUND,
    gritBatches: gritBatches
  }
}

export function packageSizeSelected(packageSize: PackageSize): PackageSizeSelected {
  return {
    type: constants.PACKAGE_SIZE_SELECTED,
    packageSize: packageSize
  }
}

export function packageSizeCreated(packageSize: PackageSize): PackageSizeCreated {
  return {
    type: constants.PACKAGE_SIZE_CREATED,
    packageSize: packageSize
  }
}

export function packageSizeDeleted(packageSizeId: string): PackageSizeDeleted {
  return {
    type: constants.PACKAGE_SIZE_DELETED,
    packageSizeId: packageSizeId
  }
}

export function treeSpeciesFound(treeSpecies: TreeSpecies[]): TreeSpeciesFound {
  return {
    type: constants.TREESPECIES_FOUND,
    treeSpecies: treeSpecies
  }
}

export function treeSpeciesSelected(treeSpecie: TreeSpecies): TreeSpecieSelected {
  return {
    type: constants.TREESPECIES_SELECTED,
    treeSpecies: treeSpecie
  }
}

export function treeSpeciesCreated(treeSpecies: TreeSpecies): TreeSpeciesCreated {
  return {
    type: constants.TREESPECIES_CREATED,
    treeSpecies: treeSpecies
  }
}

export function treeSpeciesDeleted(treeSpeciesId: string): TreeSpeciesDeleted {
  return {
    type: constants.TREESPECIES_DELETED,
    treeSpeciesId: treeSpeciesId
  }
}

export function locationsFound(locations: Location[]): LocationsFound {
  return {
    type: constants.LOCATIONS_FOUND,
    locations: locations
  }
}

export function locationSelected(location: Location): LocationSelected {
  return {
    type: constants.LOCATION_SELECTED,
    location: location
  }
}

export function locationCreated(location: Location): LocationCreated {
  return {
    type: constants.LOCATION_CREATED,
    location: location
  }
}

export function locationDeleted(locationId: string): LocationDeleted {
  return {
    type: constants.LOCATION_DELETED,
    locationId: locationId
  }
}

export function seedBatchesFound(seedBatches: SeedBatch[]): SeedBatchesFound {
  return {
    type: constants.SEED_BATCHES_FOUND,
    seedBatches: seedBatches
  }
}

export function seedBatchSelected(seedBatch: SeedBatch): SeedBatchSelected {
  return {
    type: constants.SEED_BATCH_SELECTED,
    seedBatch: seedBatch
  }
}

export function seedBatchCreated(seedBatch: SeedBatch): SeedBatchCreated {
  return {
    type: constants.SEED_BATCH_CREATED,
    seedBatch: seedBatch
  }
}

export function seedBatchDeleted(seedBatchId: string): SeedBatchDeleted {
  return {
    type: constants.SEED_BATCH_DELETED,
    seedBatchId: seedBatchId
  }
}

export function peatBatchDeleted(peatBatchId: string): PeatBatchDeleted {
  return {
    type: constants.PEAT_BATCH_DELETED,
    peatBatchId: peatBatchId
  }
}

export function gritBatchDeleted(gritBatchId: string): GritBatchDeleted {
  return {
    type: constants.GRIT_BATCH_DELETED,
    gritBatchId: gritBatchId
  }
}

export function gritBatchSelected(gritBatch: GritBatch): GritBatchSelected {
  return {
    type: constants.GRIT_BATCH_SELECTED,
    gritBatch: gritBatch
  }
}

export function peatBatchSelected(peatBatch: PeatBatch): PeatBatchSelected {
  return {
    type: constants.PEAT_BATCH_SELECTED,
    peatBatch: peatBatch
  }
}

export function performedCultivationActionsFound(performedCultivationActions: CultivationAction[]): PerformedCultivationActionsFound {
  return {
    type: constants.PERFORMED_CULTIVATION_ACTIONS_FOUND,
    performedCultivationActions: performedCultivationActions
  }
}

export function performedCultivationActionSelected(performedCultivationAction: CultivationAction): PerformedCultivationActionSelected {
  return {
    type: constants.PERFORMED_CULTIVATION_ACTION_SELECTED,
    performedCultivationAction: performedCultivationAction
  }
}

export function performedCultivationActionCreated(performedCultivationAction: CultivationAction): PerformedCultivationActionCreated {
  return {
    type: constants.PERFORMED_CULTIVATION_ACTION_CREATED,
    performedCultivationAction: performedCultivationAction
  }
}

export function performedCultivationActionDeleted(performedCultivationActionId: string): PerformedCultivationActionDeleted {
  return {
    type: constants.PERFORMED_CULTIVATION_ACTION_DELETED,
    performedCultivationActionId: performedCultivationActionId
  }
}

/**
 * Store update method for locale
 * 
 * @param locale locale
 */
export function localeUpdate(locale: string): LocaleUpdate {
  return {
    type: constants.LOCALE_UPDATE,
    locale: locale
  }
}

/**
 * Pests found action
 * 
 * @param pests list of pests
 */
export function pestsFound(pests: Pest[]): PestsFound {
  return {
    type: constants.PESTS_FOUND,
    pests: pests
  }
}

/**
 * Action wastageReasonsFound
 * 
 * @param wastageReasons array of wastage reasons
 * @return { type, wastageReasons }
 */
export function wastageReasonsFound(wastageReasons: WastageReason[]): WastageReasonsFound {
  return {
    type: constants.WASTAGE_REASONS_FOUND,
    wastageReasons: wastageReasons
  }
}

/**
 * Action wastageReasonSelected
 * 
 * @param wastageReason wastage reason
 * @return { type, wastageReason }
 */
export function wastageReasonSelected(wastageReason: WastageReason): WastageReasonSelected {
  return {
    type: constants.WASTAGE_REASON_SELECTED,
    wastageReason: wastageReason
  }
}

/**
 * Action wastageReasonCreated
 * 
 * @param wastageReason wastage reason
 * @return { type, wastageReason }
 */
export function wastageReasonCreated(wastageReason: WastageReason): WastageReasonCreated {
  return {
    type: constants.WASTAGE_REASON_CREATED,
    wastageReason: wastageReason
  }
}

/**
 * Action wastageReasonDeleted
 * 
 * @param wastageReasonId wastage reason id
 * @return { type, wastageReasonId }
 */
export function wastageReasonDeleted(wastageReasonId: string): WastageReasonDeleted {
  return {
    type: constants.WASTAGE_REASON_DELETED,
    wastageReasonId: wastageReasonId
  }
}
/**
 * Action onErrorOccurred
 * 
 * @param error error
 * @return { type, error }
 */
export function onErrorOccurred(error?: ErrorMessage): ErrorOccurred {
  return {
    type: constants.ERROR_OCCURRED,
    error: error
  }
}

export function eventListFiltersUpdated(filters: EventListFilters): EventListFiltersUpdated {
  return {
    type: constants.EVENT_LIST_FILTERS_UPDATED,
    eventListFilters: filters
  }
}

export function eventsFound(events: Event[]): EventsFound {
  return {
    type: constants.EVENTS_FOUND,
    events: events
  }
}

/**
 * Store update method for facility
 * 
 * @param facility facility
 */
 export function facilityUpdate(facility: Facility): FacilityUpdate {
  return {
    type: constants.FACILITY_UPDATE,
    facility: facility
  }
}
