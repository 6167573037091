/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    GritBatch,
    GritBatchFromJSON,
    GritBatchToJSON,
} from '../models';

export interface CreateGritBatchRequest {
    gritBatch: GritBatch;
    facility: Facility;
}

export interface DeleteGritBatchRequest {
    facility: Facility;
    gritBatchId: string;
}

export interface FindGritBatchRequest {
    facility: Facility;
    gritBatchId: string;
}

export interface ListGritBatchesRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
    createdBefore?: string;
    createdAfter?: string;
}

export interface UpdateGritBatchRequest {
    gritBatch: GritBatch;
    facility: Facility;
    gritBatchId: string;
}

/**
 * 
 */
export class GritBatchesApi extends runtime.BaseAPI {

    /**
     * Create new peat batch
     */
    async createGritBatchRaw(requestParameters: CreateGritBatchRequest): Promise<runtime.ApiResponse<GritBatch>> {
        if (requestParameters.gritBatch === null || requestParameters.gritBatch === undefined) {
            throw new runtime.RequiredError('gritBatch','Required parameter requestParameters.gritBatch was null or undefined when calling createGritBatch.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createGritBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/gritBatches`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GritBatchToJSON(requestParameters.gritBatch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GritBatchFromJSON(jsonValue));
    }

    /**
     * Create new peat batch
     */
    async createGritBatch(requestParameters: CreateGritBatchRequest): Promise<GritBatch> {
        const response = await this.createGritBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a peat batch
     */
    async deleteGritBatchRaw(requestParameters: DeleteGritBatchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteGritBatch.');
        }

        if (requestParameters.gritBatchId === null || requestParameters.gritBatchId === undefined) {
            throw new runtime.RequiredError('gritBatchId','Required parameter requestParameters.gritBatchId was null or undefined when calling deleteGritBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/gritBatches/{gritBatchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"gritBatchId"}}`, encodeURIComponent(String(requestParameters.gritBatchId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a peat batch
     */
    async deleteGritBatch(requestParameters: DeleteGritBatchRequest): Promise<void> {
        await this.deleteGritBatchRaw(requestParameters);
    }

    /**
     * Find a peat batch
     */
    async findGritBatchRaw(requestParameters: FindGritBatchRequest): Promise<runtime.ApiResponse<GritBatch>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findGritBatch.');
        }

        if (requestParameters.gritBatchId === null || requestParameters.gritBatchId === undefined) {
            throw new runtime.RequiredError('gritBatchId','Required parameter requestParameters.gritBatchId was null or undefined when calling findGritBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/gritBatches/{gritBatchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"gritBatchId"}}`, encodeURIComponent(String(requestParameters.gritBatchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GritBatchFromJSON(jsonValue));
    }

    /**
     * Find a peat batch
     */
    async findGritBatch(requestParameters: FindGritBatchRequest): Promise<GritBatch> {
        const response = await this.findGritBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all peat batches
     */
    async listGritBatchesRaw(requestParameters: ListGritBatchesRequest): Promise<runtime.ApiResponse<Array<GritBatch>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listGritBatches.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/gritBatches`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GritBatchFromJSON));
    }

    /**
     * List all peat batches
     */
    async listGritBatches(requestParameters: ListGritBatchesRequest): Promise<Array<GritBatch>> {
        const response = await this.listGritBatchesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a peat batch
     */
    async updateGritBatchRaw(requestParameters: UpdateGritBatchRequest): Promise<runtime.ApiResponse<GritBatch>> {
        if (requestParameters.gritBatch === null || requestParameters.gritBatch === undefined) {
            throw new runtime.RequiredError('gritBatch','Required parameter requestParameters.gritBatch was null or undefined when calling updateGritBatch.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateGritBatch.');
        }

        if (requestParameters.gritBatchId === null || requestParameters.gritBatchId === undefined) {
            throw new runtime.RequiredError('gritBatchId','Required parameter requestParameters.gritBatchId was null or undefined when calling updateGritBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/gritBatches/{gritBatchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"gritBatchId"}}`, encodeURIComponent(String(requestParameters.gritBatchId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GritBatchToJSON(requestParameters.gritBatch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GritBatchFromJSON(jsonValue));
    }

    /**
     * Updates a peat batch
     */
    async updateGritBatch(requestParameters: UpdateGritBatchRequest): Promise<GritBatch> {
        const response = await this.updateGritBatchRaw(requestParameters);
        return await response.value();
    }

}
