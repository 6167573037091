import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";import Api from "../../api";
import { ProductInfo, Facility, TreeSpecies } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";

import {
  Grid,
  Button,
  Loader,
  Form,
  Message,
  Confirm,
  DropdownProps,
  InputOnChangeData
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";
import { FormContainer } from "../FormContainer";

/**
 * Interface representing component properties
 */
interface Props {
  keycloak?: Keycloak;
  productInfoId: string;
  productInfo?: ProductInfo;
  facility: Facility;
  onProductInfoSelected?: (productInfo: ProductInfo) => void;
  onProductInfoDeleted?: (productInfoId: string) => void;
  onTreeSpeciesFound?: (treeSpecies: TreeSpecies[]) => void;
  onError: (error: ErrorMessage | undefined) => void;
}

/**
 * Interface representing component state
 */
interface State {
  productInfo?: ProductInfo;
  redirect: boolean;
  saving: boolean;
  messageVisible: boolean;
  open: boolean;
  treeSpecies?: TreeSpecies[];
}

/**
 * React component for edit productInfo view
 */
class EditProductInfo extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      productInfo: undefined,
      redirect: false,
      saving: false,
      messageVisible: false,
      open:false,
      treeSpecies: []
    };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    const { keycloak, productInfoId, facility, onProductInfoSelected, onError, onTreeSpeciesFound } = this.props;

    if (!keycloak) {
      return;
    }

    try {
      const productInfosService = await Api.getProductInfoService(keycloak);
      const productInfo = await productInfosService.findProductInfo({
        productInfoId: productInfoId,
        facility: facility
      });

      onProductInfoSelected && onProductInfoSelected(productInfo);
      this.setState({ productInfo });

      const treeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      const treeSpecies = await treeSpeciesService.listTreeSpecies({
        facility: facility
      });
      onTreeSpeciesFound && onTreeSpeciesFound(treeSpecies);
      this.setState({ treeSpecies: treeSpecies });
    } catch (e: any) {
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Handle form submit
   */
  private handleSubmit = async () => {
    const { keycloak, facility, onError } = this.props;
    const { productInfo } = this.state;
    try {
      if (!keycloak || !productInfo) {
        return;
      }

      const productInfosService = await Api.getProductInfoService(keycloak);

      this.setState({ saving: true });
      await productInfosService.updateProductInfo({
        productInfoId: productInfo.id!,
        productInfo: productInfo,
        facility: facility
      });
      this.setState({ saving: false });

      this.setState({ messageVisible: true });
      setTimeout(() => {
        this.setState({ messageVisible: false });
      }, 3000);
      this.setState({ redirect: true });
    } catch (e: any) {
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: e
      });
    }
  }

  /**
   * Handle productInfo delete
   */
  private handleDelete = async () => {
    const { keycloak, facility } = this.props;
    const { productInfo } = this.state;
    if (!keycloak || !productInfo) {
      return;
    }

    const productInfosService = await Api.getProductInfoService(keycloak);
    const productInfoId = productInfo.id || "";

    await productInfosService.deleteProductInfo({
      productInfoId: productInfoId,
      facility: facility
    });

    this.props.onProductInfoDeleted && this.props.onProductInfoDeleted(productInfoId!);
    this.setState({ redirect: true });
  }

  /**
   * Handle tree species select event change
   *
   * @param e event
   * @param value updated tree species
   */
  private onTreeSpeciesChange = (e: any, { value }: DropdownProps) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo!,
        treeSpeciesId: value as string
      }
    });
  }

  /**
   * Handle cells event change
   *
   * @param e event
   * @param value cells
   */
  private onCellsChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo!,
        cells: Number.parseInt(value)
      }
    });
  }

  /**
   * Handle boxes event change
   *
   * @param e event
   * @param value boxes
   */
  private onBoxesChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo!,
        boxes: Number.parseInt(value)
      }
    });
  }

  /**
   * Handle platform event change
   *
   * @param e event
   * @param value platform
   */
  private onPlatformsChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo!,
        platforms: Number.parseInt(value)
      }
    });
  }

    /**
   * Handle sales bags change
   *
   * @param e event
   * @param value bags
   */
  private onBagsChange = (e: any, { value }: InputOnChangeData) => {
    this.setState({
      productInfo: {
        ...this.state.productInfo!,
        bags: Number.parseInt(value)
      }
    });
  }

  /**
   * Render edit productInfo view
   */
  public render() {
    const { productInfo, treeSpecies, saving } = this.state;

    if (!productInfo) {
      return (
        <Grid centered style={{ paddingTop: "100px" }}>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    if (this.state.redirect) {
      return <Navigate to="/productInfos" replace={true}/>;
    }

    const treeSpeciesOptions = (treeSpecies || []).map(treeSpecie => ({
      key: treeSpecie.id,
      text: LocalizedUtils.getLocalizedValue(treeSpecie.name),
      value: treeSpecie.id
    }));

    const selectedTreeSpecie = treeSpecies?.find(specie => specie.id === productInfo.treeSpeciesId);
    const treeSpecieName = LocalizedUtils.getLocalizedValue(selectedTreeSpecie?.name);
    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={ 6 }>
            <h2>{ strings.productInfos }</h2>
          </Grid.Column>
          <Grid.Column width={ 3 } floated="right">
            <Button
              className="danger-button"
              onClick={ () => this.setState({ open: true }) }
            >
              { strings.delete }
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={ 8 }>
            <FormContainer>
            <Form.Select
                fluid
                required
                label={ strings.treeSpecies }
                options={ treeSpeciesOptions }
                value={ productInfo.treeSpeciesId }
                onChange={ this.onTreeSpeciesChange }
              />
              <Form.Input
                required
                label={ strings.boxes }
                name="boxes"
                type="number"
                value={ productInfo.boxes }
                onChange={ this.onBoxesChange }
              />
              <Form.Input
                required
                label={ strings.bags }
                name="bags"
                type="number"
                value={ productInfo.bags }
                onChange={ this.onBagsChange }
              />
              <Form.Input
                required
                label={ strings.cells }
                name="cells"
                type="number"
                value={ productInfo.cells }
                onChange={ this.onCellsChange }
              />
              <Form.Input
                required
                label={ strings.treeSpeciesPlatforms }
                name="platforms"
                type="number"
                value={ productInfo.platforms }
                onChange={ this.onPlatformsChange }
              />
              <Message
                success
                visible={ this.state.messageVisible }
                header={ strings.savedSuccessfully }
              />
              <Button
                className="submit-button"
                onClick={ this.handleSubmit }
                type='submit'
                loading={ saving }
              >
                { strings.save }
              </Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
        <Confirm
          open={ this.state.open }
          size="mini"
          content={ strings.deleteConfirmationText + treeSpecieName}
          onCancel={ () => this.setState({ open:false }) }
          onConfirm={ this.handleDelete }
        />
      </Grid>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: StoreState) => ({
  productInfos: state.productInfos,
  productInfo: state.productInfo,
  treeSpecies: state.treeSpecies,
  facility: state.facility
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<actions.AppAction>) => ({
  onProductInfoSelected: (productInfo: ProductInfo) => dispatch(actions.productInfoSelected(productInfo)),
  onProductInfoDeleted: (productInfoId: string) => dispatch(actions.productInfoDeleted(productInfoId)),
  onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
  onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProductInfo);
