/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    PeatBatch,
    PeatBatchFromJSON,
    PeatBatchToJSON,
} from '../models';

export interface CreatePeatBatchRequest {
    peatBatch: PeatBatch;
    facility: Facility;
}

export interface DeletePeatBatchRequest {
    facility: Facility;
    peatBatchId: string;
}

export interface FindPeatBatchRequest {
    facility: Facility;
    peatBatchId: string;
}

export interface ListPeatBatchesRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
    createdBefore?: string;
    createdAfter?: string;
}

export interface UpdatePeatBatchRequest {
    peatBatch: PeatBatch;
    facility: Facility;
    peatBatchId: string;
}

/**
 * 
 */
export class PeatBatchesApi extends runtime.BaseAPI {

    /**
     * Create new peat batch
     */
    async createPeatBatchRaw(requestParameters: CreatePeatBatchRequest): Promise<runtime.ApiResponse<PeatBatch>> {
        if (requestParameters.peatBatch === null || requestParameters.peatBatch === undefined) {
            throw new runtime.RequiredError('peatBatch','Required parameter requestParameters.peatBatch was null or undefined when calling createPeatBatch.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createPeatBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/peatBatches`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PeatBatchToJSON(requestParameters.peatBatch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeatBatchFromJSON(jsonValue));
    }

    /**
     * Create new peat batch
     */
    async createPeatBatch(requestParameters: CreatePeatBatchRequest): Promise<PeatBatch> {
        const response = await this.createPeatBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a peat batch
     */
    async deletePeatBatchRaw(requestParameters: DeletePeatBatchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deletePeatBatch.');
        }

        if (requestParameters.peatBatchId === null || requestParameters.peatBatchId === undefined) {
            throw new runtime.RequiredError('peatBatchId','Required parameter requestParameters.peatBatchId was null or undefined when calling deletePeatBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/peatBatches/{peatBatchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"peatBatchId"}}`, encodeURIComponent(String(requestParameters.peatBatchId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a peat batch
     */
    async deletePeatBatch(requestParameters: DeletePeatBatchRequest): Promise<void> {
        await this.deletePeatBatchRaw(requestParameters);
    }

    /**
     * Find a peat batch
     */
    async findPeatBatchRaw(requestParameters: FindPeatBatchRequest): Promise<runtime.ApiResponse<PeatBatch>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findPeatBatch.');
        }

        if (requestParameters.peatBatchId === null || requestParameters.peatBatchId === undefined) {
            throw new runtime.RequiredError('peatBatchId','Required parameter requestParameters.peatBatchId was null or undefined when calling findPeatBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/peatBatches/{peatBatchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"peatBatchId"}}`, encodeURIComponent(String(requestParameters.peatBatchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeatBatchFromJSON(jsonValue));
    }

    /**
     * Find a peat batch
     */
    async findPeatBatch(requestParameters: FindPeatBatchRequest): Promise<PeatBatch> {
        const response = await this.findPeatBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all peat batches
     */
    async listPeatBatchesRaw(requestParameters: ListPeatBatchesRequest): Promise<runtime.ApiResponse<Array<PeatBatch>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listPeatBatches.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/peatBatches`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeatBatchFromJSON));
    }

    /**
     * List all peat batches
     */
    async listPeatBatches(requestParameters: ListPeatBatchesRequest): Promise<Array<PeatBatch>> {
        const response = await this.listPeatBatchesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a peat batch
     */
    async updatePeatBatchRaw(requestParameters: UpdatePeatBatchRequest): Promise<runtime.ApiResponse<PeatBatch>> {
        if (requestParameters.peatBatch === null || requestParameters.peatBatch === undefined) {
            throw new runtime.RequiredError('peatBatch','Required parameter requestParameters.peatBatch was null or undefined when calling updatePeatBatch.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updatePeatBatch.');
        }

        if (requestParameters.peatBatchId === null || requestParameters.peatBatchId === undefined) {
            throw new runtime.RequiredError('peatBatchId','Required parameter requestParameters.peatBatchId was null or undefined when calling updatePeatBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/peatBatches/{peatBatchId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"peatBatchId"}}`, encodeURIComponent(String(requestParameters.peatBatchId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PeatBatchToJSON(requestParameters.peatBatch),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeatBatchFromJSON(jsonValue));
    }

    /**
     * Updates a peat batch
     */
    async updatePeatBatch(requestParameters: UpdatePeatBatchRequest): Promise<PeatBatch> {
        const response = await this.updatePeatBatchRaw(requestParameters);
        return await response.value();
    }

}
