/* tslint:disable */
/* eslint-disable */
/**
 * UPM-metsa-api
 * UPM Metsä API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface PeatBatch
 */
export interface PeatBatch {
    /**
     * 
     * @type {string}
     * @memberof PeatBatch
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PeatBatch
     */
    batchNumber: string;
    /**
     * 
     * @type {number}
     * @memberof PeatBatch
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PeatBatch
     */
    supplier: string;
    /**
     * 
     * @type {Date}
     * @memberof PeatBatch
     */
    deliveryDate: Date;
    /**
     * 
     * @type {Metadata}
     * @memberof PeatBatch
     */
    metadata?: Metadata;
}

export function PeatBatchFromJSON(json: any): PeatBatch {
    return PeatBatchFromJSONTyped(json, false);
}

export function PeatBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeatBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'batchNumber': json['batchNumber'],
        'amount': json['amount'],
        'supplier': json['supplier'],
        'deliveryDate': (new Date(json['deliveryDate'])),
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
    };
}

export function PeatBatchToJSON(value?: PeatBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchNumber': value.batchNumber,
        'amount': value.amount,
        'supplier': value.supplier,
        'deliveryDate': (value.deliveryDate.toISOString()),
        'metadata': MetadataToJSON(value.metadata),
    };
}


