import { AppAction } from "../actions";
import { StoreState } from "../types/index";
import {
  USER_LOGIN,
  USER_LOGOUT,
  PRODUCT_INFO_SELECTED,
  PRODUCT_INFO_DELETED,
  PACKAGE_SIZES_FOUND,
  PACKAGE_SIZE_DELETED,
  PACKAGE_SIZE_SELECTED,
  LOCATIONS_FOUND,
  LOCATION_DELETED,
  LOCATION_SELECTED,
  SEED_BATCHES_FOUND,
  SEED_BATCH_SELECTED,
  SEED_BATCH_DELETED,
  PERFORMED_CULTIVATION_ACTIONS_FOUND,
  PERFORMED_CULTIVATION_ACTION_DELETED,
  PERFORMED_CULTIVATION_ACTION_SELECTED,
  WASTAGE_REASONS_FOUND,
  WASTAGE_REASON_DELETED,
  WASTAGE_REASON_SELECTED,
  LOCALE_UPDATE,
  PESTS_FOUND,
  ERROR_OCCURRED,
  CAMPAIGNS_FOUND,
  EVENT_LIST_FILTERS_UPDATED,
  EVENTS_FOUND,
  FACILITY_UPDATE,
  PEAT_BATCHES_FOUND,
  GRIT_BATCHES_FOUND,
  PRODUCT_INFOS_FOUND
} from "../constants/index";
import { Reducer } from "redux";
import { Facility } from "../generated/client/models/Facility";
import { GritBatch, Location, PeatBatch } from "../generated/client";

/**
 * Process action
 *
 * @param state state
 * @param action action
 */
export const processAction: Reducer<StoreState, AppAction> = (
  state: StoreState | undefined,
  action: AppAction,
): StoreState => {
  if (!state) {
    state = {
      authenticated: false,
      locale: "fi_joroinen",
      events: [],
      eventListFilters: {},
      campaigns: [],
      packageSize: {} as any,
      packageSizes: [],
      performedCultivationAction: {} as any,
      performedCultivationActions: [],
      pests: [],
      productInfo: {} as any,
      location: {} as Location,
      locations: [] as Location[],
      productInfos: [],
      seedBatch: {} as any,
      seedBatches: [],
      storageDiscards: [],
      wastageReason: {} as any,
      wastageReasons: [],
      facility: Facility.Joroinen,
      treeSpecies: [],
      tree: {} as any,
      peatBatches: [] as PeatBatch[],
      gritBatches: [] as GritBatch[]
    };
  }
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, keycloak: action.keycloak, authenticated: action.authenticated };
    case USER_LOGOUT:
      return { ...state, keycloak: undefined, authenticated: false };
    case PRODUCT_INFOS_FOUND:
      return { ...state, productInfos: action.productInfos };
    case PRODUCT_INFO_SELECTED:
      return { ...state, productInfo: action.productInfo };
    case PRODUCT_INFO_DELETED:
      return {
        ...state,
        productInfos: (state.productInfos || []).filter((productInfo) => {
          return productInfo.id !== action.productInfoId;
        }),
      };
    case PACKAGE_SIZES_FOUND:
      return { ...state, packageSizes: action.packageSizes };
    case PACKAGE_SIZE_SELECTED:
      return { ...state, packageSize: action.packageSize };
    case PACKAGE_SIZE_DELETED:
      return {
        ...state,
        packageSizes: (state.packageSizes || []).filter((packageSize) => {
          return packageSize.id !== action.packageSizeId;
        }),
      };
    case LOCATIONS_FOUND:
      return { ...state, locations: action.locations };
    case LOCATION_SELECTED:
      return { ...state, location: action.location };
    case LOCATION_DELETED:
      return {
        ...state,
        locations: (state.locations || []).filter((location) => {
          return location.id !== action.locationId;
        }),
      };
    case SEED_BATCHES_FOUND:
      return { ...state, seedBatches: action.seedBatches };
    case SEED_BATCH_SELECTED:
      return { ...state, seedBatch: action.seedBatch };
    case SEED_BATCH_DELETED:
      return {
        ...state,
        seedBatches: (state.seedBatches || []).filter((seedBatch) => {
          return seedBatch.id !== action.seedBatchId;
        }),
      };
    case PERFORMED_CULTIVATION_ACTIONS_FOUND:
      return { ...state, performedCultivationActions: action.performedCultivationActions };
    case PERFORMED_CULTIVATION_ACTION_SELECTED:
      return { ...state, performedCultivationAction: action.performedCultivationAction };
    case PERFORMED_CULTIVATION_ACTION_DELETED:
      return {
        ...state,
        performedCultivationActions: (state.performedCultivationActions || []).filter((performedCultivationAction) => {
          return performedCultivationAction.id !== action.performedCultivationActionId;
        }),
      };
    case LOCALE_UPDATE: {
      const locale = action.locale;
      return { ...state, locale: locale };
    }
    case WASTAGE_REASONS_FOUND:
      return { ...state, wastageReasons: action.wastageReasons };
    case WASTAGE_REASON_SELECTED:
      return { ...state, wastageReason: action.wastageReason };
    case WASTAGE_REASON_DELETED:
      return {
        ...state,
        wastageReasons: (state.wastageReasons || []).filter((wastageReason) => {
          return wastageReason.id !== action.wastageReasonId;
        }),
      };
    case PESTS_FOUND:
      return { ...state, pests: action.pests };
    case ERROR_OCCURRED:
      return { ...state, error: action.error };
    case CAMPAIGNS_FOUND:
      return { ...state, campaigns: action.campaigns };
    case EVENT_LIST_FILTERS_UPDATED:
      return { ...state, eventListFilters: action.eventListFilters };
    case EVENTS_FOUND:
      return { ...state, events: action.events };
    case FACILITY_UPDATE:
      return { ...state, facility: action.facility };
    case GRIT_BATCHES_FOUND:
      return { ...state, gritBatches: action.gritBatches };
    case PEAT_BATCHES_FOUND:
      return { ...state, peatBatches: action.peatBatches };
  }
  return state;
};
